import React, {useState, useEffect} from "react";
import { RouterProvider } from "react-router-dom"; // Import RouterProvider
import AppRoutes from "./routes/AppRoutes.js";
const App = () => {
const [location, setLocation] = useState({ latitude: null, longitude: null });
const [error, setError] = useState(null);
const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        if(!localStorage.getItem('location')){
           localStorage.setItem('userLoc',JSON.stringify({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
           }))
           fetchLocationDetails(position.coords.latitude, position.coords.longitude);
        }
        setError(null);
      },
      (err) => {
        setError(err.message);
      }
    );
  } else {
    setError('Geolocation is not supported by your browser.');
  }
};

const fetchLocationDetails = async (lat, lon) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
    );
    const data = await response.json();
    if (data.address) {
      localStorage.setItem('location', JSON.stringify(data.address))
    } else {
      console.error('No address found.');
    }
  } catch (error) {
    console.error('Error fetching location:', error);
  }
};

const generateSessionId = () => {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

useEffect(()=>{
  const newSessionId = generateSessionId();
  let sessionId = localStorage.getItem("sessionId")
  if(!sessionId){
    localStorage.setItem("sessionId", newSessionId);
  }
  getLocation()
}, [])

return (
  <RouterProvider router={AppRoutes} />
  )
};

export default App;
