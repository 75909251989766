import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counterSlice";
import authReducer from "./slices/authSlice";
import favouriteReducer from "./slices/favouriteSlice";
import recentsearchReducer from "./slices/recentsearchSlice";
import recentviewedReducer from "./slices/recentviewedSlice";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    favourite : favouriteReducer,
    recentsearch : recentsearchReducer,
    recentviewed : recentviewedReducer
  },
});

export default store;
