import { createSlice } from '@reduxjs/toolkit';
let userIDlocal = localStorage.getItem("userID")
let accessToken = localStorage.getItem("accessToken")
let userData = localStorage.getItem("userData")
const initialState = {
  isAuthenticated: (userIDlocal) ? true : false,
  authToken:(accessToken) ? accessToken : null,
  user: (userData) ? JSON.parse(atob(userData)) : null,
  userId:(userIDlocal) ? atob(userIDlocal) : null,
  tiggerLogin:false,
  tiggerSignup:false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogin: (state, action) => {
      state.isAuthenticated = true;
      state.userId = action.payload.userID
      state.authToken = action.payload.authToken
      state.user = action.payload;
    },
    toggleLogin: (state, action) => {
      state.tiggerLogin = action.payload.open;
    },
    tiggerSignupPopup: (state, action) => {
        state.tiggerSignup = action.payload.open;
    },
    userLogout: (state) => {
      state.isAuthenticated = false;
      state.authToken = ''
      state.user = null;
      state.userId = null;
      state.tiggerLogin = false;
      state.tiggerSignup = false;
    },
  },
});

export const { userLogin, userLogout,toggleLogin,tiggerSignupPopup } = authSlice.actions;
export default authSlice.reducer;
