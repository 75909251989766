// SignIn.js
import React, { useState } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleRegisterButton from './GoogleRegisterButton';
const Register = ({ onRegister, toggleSignIn }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "first_name":
        if (!value.trim()) error = "First name is required.";
        break;

      case "last_name":
        if (!value.trim()) error = "Last name is required.";
        break;

      case "email":
        if (!value.trim()) error = "Email is required.";
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value))
          error = "Invalid email format.";
        break;

      case "mobile_number":
        if (!value.trim()) error = "Mobile number is required.";
        else if (!/^\d{10}$/.test(value))
          error = "Mobile number must be 10 digits.";
        break;

      case "password":
        if (!value.trim()) error = "Password is required.";
        else if (value.length < 6)
          error = "Password must be at least 6 characters.";
        break;

      default:
        break;
    }

    return error;
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update formData
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validate on change and clear error for that field
    setErrors((prev) => ({
      ...prev,
      [name]: validateField(name, value) || "",
    }));
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (validateForm()) {
      onRegister(formData);
     
    }
  };

  return (
    <div className="auth-data">
      <form onSubmit={handleRegister}>
        <div className="section-title">
          <div className="heading">
            <h2>Sign Up</h2>
            <p>
              Already have an account?{" "}
              <a href="#" onClick={toggleSignIn}>
                Login
              </a>
            </p>
          </div>
        </div>

        {/* First Name Field */}
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
            placeholder="First Name"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
          />
          {errors.first_name && (
            <div className="invalid-feedback">{errors.first_name}</div>
          )}
        </div>

        {/* Last Name Field */}
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
            placeholder="Last Name"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
          />
          {errors.last_name && (
            <div className="invalid-feedback">{errors.last_name}</div>
          )}
        </div>

        {/* Email Field */}
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            placeholder="E-mail"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>

        {/* Mobile Number Field */}
        <div className="form-group">
          <label>Mobile Number</label>
          <input
            type="text"
            className={`form-control ${
              errors.mobile_number ? "is-invalid" : ""
            }`}
            placeholder="Mobile Number"
            name="mobile_number"
            value={formData.mobile_number}
            onChange={handleChange}
          />
          {errors.mobile_number && (
            <div className="invalid-feedback">{errors.mobile_number}</div>
          )}
        </div>

        {/* Password Field */}
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && (
            <div className="invalid-feedback">{errors.password}</div>
          )}
        </div>

        {/* Submit Button */}
        
        <div className="row">
            <div className="col-12 col-sm-6">
              <GoogleOAuthProvider clientId="311374789087-9phrp4obl9661rce88ij4tj5is069f95.apps.googleusercontent.com">
                <div>
                  <GoogleRegisterButton />
                </div>
              </GoogleOAuthProvider>
            </div>
            <div className="col-12 col-sm-6">
              <div className="button-holder">
                <button type="submit" className="btn btn-primary">
                  Register
                </button>
              </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
