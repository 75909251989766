import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  recentSearchList: [],
  searchTerm : '',
  propertyData : [],
};

const recentsearchSlice = createSlice({
  name: 'recentsearch',
  initialState,
  reducers: {
    setRecentSearchList: (state, action) => {
      state.recentSearchList = action.payload;
    },
    setSearchTearm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setPropertyData: (state, action) => {
      state.propertyData = action.payload;
    }
  },
});

export const { setRecentSearchList, setSearchTearm,setPropertyData } = recentsearchSlice.actions;
export default recentsearchSlice.reducer;
