import React, { createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ToastContext = createContext();
export const ToastProvider = ({ children }) => {
  const showToast = (message, type = 'success') => {
    if (type === 'success') {
      toast.success(message, {
        position: "bottom-right"
      });
    } else if (type === 'error') {
      toast.error(message,{
        position: "bottom-right"
      });
    } else {
      toast.info(message,{
        position: "bottom-right"
      });
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};
export const useToast = () => {
  return useContext(ToastContext);
};