import React, { Suspense, lazy } from "react";
import { createHashRouter } from "react-router-dom";
import CommonLayout from "../layouts/commonLayout";
import CommonPageLoader from "../components/common/CommonPageLoader";
import FadeTransition from "../components/common/FadeTransition";

// Lazy-loaded pages
const HomePage = lazy(() => import("../pages/HomePage"));
const LocationPage = lazy(() => import("../pages/LocationPage"));
const FavoritePage = lazy(() => import("../pages/FavoritePage"));
const RecentlySpotted = lazy(() => import("../pages/RecentlySpotted"));
const PropertyDetails = lazy(() => import("../pages/PropertyDetails"));
const RecentlySearched = lazy(() => import("../pages/RecentlySearched"));
const FileUpload = lazy(() => import("../pages/FileUpload"));
const ContactUs = lazy(() => import("../pages/Contactus"));
const RealtorLayout = lazy(() => import("../layouts/realtorLayout")); // Correct capitalization
const RealtorChat = lazy(() => import("../pages/Realtor/RealtorChat"));
const RealtorAllCustomer = lazy(() => import("../pages/Realtor/RealtorAllCustomer"));
const RealtorCreateCustomer = lazy(() => import("../pages/Realtor/RealtorCreateCustomer"));
const RealtorPersonas = lazy(() => import("../pages/Realtor/RealtorPersonas"));
const RealtorCreatePersonas = lazy(() => import("../pages/Realtor/RealtorCreatePersonas"));
const DataCollections = lazy(() => import("../pages/Realtor/DataCollections"));
const DataCollectionsFiles = lazy(() => import("../pages/Realtor/DataCollectionsFiles"));
const DataCollectionsFileCollection = lazy(() => import("../pages/Realtor/DataCollectionsFileCollection"));
const TermsandConditions = lazy(() => import("../pages/termsandConditions"));
const Company = lazy(() => import("../pages/Company"));
const PrivacyPolicy = lazy(() => import("../pages/privacyPolicy"));
const Howitworks = lazy(() => import("../pages/Howitworks"));
const Investors = lazy(() => import("../pages/Investors"));


// Create router configuration
const router = createHashRouter([
  {
    path: "/",
    element: <CommonLayout />,
    children: [
      {
        index: true, // Default route
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <HomePage />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "location",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <LocationPage />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "favorite",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <FavoritePage />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "recently-viewed",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <RecentlySpotted />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "property/:propertyId",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <PropertyDetails />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "recently-searched",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <RecentlySearched />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "realtor",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <RealtorLayout />
          </Suspense>
        ),
        children: [
          {
            index: true, // Default child route for realtor
            element: (
              <Suspense fallback={<CommonPageLoader />}>
                <FadeTransition>
                  <RealtorChat />
                </FadeTransition>
              </Suspense>
            ),
          },
          {
            path: "all-customers",
            element: (
              <Suspense fallback={<CommonPageLoader />}>
                <FadeTransition>
                  <RealtorAllCustomer />
                </FadeTransition>
              </Suspense>
            ),
          },
          {
            path: "all-customers/create",
            element: (
              <Suspense fallback={<CommonPageLoader />}>
                <FadeTransition>
                  <RealtorCreateCustomer />
                </FadeTransition>
              </Suspense>
            ),
          },
          {
            path: "all-personas",
            element: (
              <Suspense fallback={<CommonPageLoader />}>
                <FadeTransition>
                  <RealtorPersonas />
                </FadeTransition>
              </Suspense>
            ),
          },
          {
            path: "all-personas/create",
            element: (
              <Suspense fallback={<CommonPageLoader />}>
                <FadeTransition>
                  <RealtorCreatePersonas />
                </FadeTransition>
              </Suspense>
            ),
          },
          {
            path: "data-collections",
            element: (
              <Suspense fallback={<CommonPageLoader />}>
                <FadeTransition>
                  <DataCollections />
                </FadeTransition>
              </Suspense>
            ),
          },
          {
            path: "data-collections/files",
            element: (
              <Suspense fallback={<CommonPageLoader />}>
                <FadeTransition>
                  <DataCollectionsFiles />
                </FadeTransition>
              </Suspense>
            ),
          },
          {
            path: "data-collections/collection-of-files",
            element: (
              <Suspense fallback={<CommonPageLoader />}>
                <FadeTransition>
                  <DataCollectionsFileCollection />
                </FadeTransition>
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "file-upload",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <FileUpload />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "terms-and-conditions",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <TermsandConditions />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "company",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <Company />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <PrivacyPolicy />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "how-it-works",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <Howitworks />
            </FadeTransition>
          </Suspense>
        ),
      },
      {
        path: "investors",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <Investors />
            </FadeTransition>
          </Suspense>
        ),
      },
      
      {
        path: "contact-us",
        element: (
          <Suspense fallback={<CommonPageLoader />}>
            <FadeTransition>
              <ContactUs />
            </FadeTransition>
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
