import React, { useState, useEffect } from "react";
const FadeTransition = ({ children, duration = 2000 }) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  return (
    <div
      className={`fade-container ${isVisible ? "fade-in" : "fade-out"}`}
      style={{ transition: `opacity ${duration}ms ease-in-out` }}
    >
      {children}
    </div>
  );
};
export default FadeTransition;