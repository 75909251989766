import React, { useState } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLoginButton from './GoogleLoginButton';
const SignIn = ({ onSignIn, toggleForgotPassword, toggleRegister }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    ) {
      newErrors.email = "Invalid email format.";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required.";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSignIn = (e) => {
    e.preventDefault();

    if (validateForm()) {
      onSignIn({ email, password, rememberMe });
    }
  };

  return (
    <div className="auth-data">
      <form onSubmit={handleSignIn}>
        <div className="section-title">
          <div className="heading">
            <h2>Sign In</h2>
            <p>
              Don’t have an account?{" "}
              <a href="#" onClick={toggleRegister}>
                Create now
              </a>
            </p>
          </div>
        </div>

        {/* Email Field */}
        <div className="form-group">
          <label>E-mail</label>
          <input
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>

        {/* Password Field */}
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && (
            <div className="invalid-feedback">{errors.password}</div>
          )}
        </div>

        {/* Remember Me and Forgot Password */}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label className="form-check-label" htmlFor="rememberMe">
                Remember Me
              </label>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div className="forgot-password">
              <a href="#" onClick={toggleForgotPassword}>
                Forgot Password?
              </a>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="button-holder">
          <div className="row">
            <div className="col-12 col-sm-6">
              <GoogleOAuthProvider clientId="311374789087-9phrp4obl9661rce88ij4tj5is069f95.apps.googleusercontent.com">
                <div>
                  <GoogleLoginButton />
                </div>
              </GoogleOAuthProvider>
            </div>
            <div className="col-12 col-sm-6">
              <button type="submit" className="btn btn-primary">
                Sign In
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignIn;