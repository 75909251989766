import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import userApi from "../../clientAPIInstance/userApi.js";
import { useLoader } from '../../customProviders/LoaderContext.js';

const Newsletter = () => {
  const { showLoader, hideLoader } = useLoader(); 
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   
    setError("");
    setSuccess(false);
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    try {
      showLoader()
      const response = await userApi.post("send-newsletter", { email });
      if (response.status === 200) {
        setSuccess(true);
        setEmail("");
      } else {
        setError("Something went wrong. Please try again later.");
      }
      setEmail("");
    } catch (error) {
      setError("Unable to subscribe. Please try again later.");
    }
    finally{
      hideLoader()
    }
  };

  return (
    <section className="home-newsletter custom-spacing">
      <div className="home-newsletter-holder">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="home-newsletter-content">
                <h2>Are you a Realtor?</h2>
                <p>Discover ways to increase your search results and increase your productivity.</p>
                <Form className="form-group" onSubmit={handleSubmit}>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
                {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                {success && <Alert variant="success" className="mt-3">You have successfully subscribed to the newsletter!</Alert>}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Newsletter;
