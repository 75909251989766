import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import { ToastProvider } from '../customProviders/ToastProvider.js';
import {LoaderProvider, useLoader} from '../customProviders/LoaderContext.js';
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import Loader from "../components/common/Loader.js";
import ScrollTopCmp  from "../components/scrollTopCmp.js";
import Newsletter from "../components/Home/HomeNewsletter.js"
import { Outlet } from "react-router-dom";
import "../assets/css/bootstrap.min.css";
import "../assets/css/swiper-bundle.min.css";
import "../assets/css/main.css";
const LoaderWrapper = () => {
  const { isLoading } = useLoader(); // Access the global isLoading
  return <Loader isLoading={isLoading} />;
};
const HomePage = ()  => {
  const location = useLocation();
  const [isRealtor, setIsRealtor] = useState(true)
  useEffect(()=>{
    if(location.pathname && location.pathname.startsWith('/realtor')){
      setIsRealtor(false)
    }
    else{
      setIsRealtor(true)
    }
  },[location.pathname])
  return (
    <main className={isRealtor ? "propaity-holder" : 'propaity-holder no-space'}>
      <ScrollTopCmp  />
      <ToastProvider> 
        <LoaderProvider>
            {isRealtor ? <Header /> : <></>}
            <Outlet />
            {isRealtor ? <Newsletter />  : <></>}
            {isRealtor ? <Footer/>  : <></>}
            <LoaderWrapper />
        </LoaderProvider>
      </ToastProvider>
    </main>
  )
};

export default HomePage;