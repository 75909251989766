import React from "react";
import { InfinitySpin } from "react-loader-spinner";

const CommonPageLoader = () => {
  return (
    <>
      <div className="page-loader">
        <div className="page-loader-holder">
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <InfinitySpin 
              width="200"
              color="#FD9E03"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonPageLoader;
