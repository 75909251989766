import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  recentViewedList: []
};

const recentviewedSlice = createSlice({
  name: 'recentviewed',
  initialState,
  reducers: {
    setRecentViewedList: (state, action) => {
      state.recentViewedList = action.payload;
    }
  },
});

export const { setRecentViewedList } = recentviewedSlice.actions;
export default recentviewedSlice.reducer;
