import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  favList: []
};

const favouriteSlice = createSlice({
  name: 'favourite',
  initialState,
  reducers: {
    getFavList: (state, action) => {
      state.favList = action.payload;
    }
  },
});

export const { getFavList } = favouriteSlice.actions;
export default favouriteSlice.reducer;
