// SignIn.js
import React, { useState } from "react";
import { Form } from "react-bootstrap";
const ForgotPassword = ({ onForgotPassword, toggleForgotPassword }) => {
  const [email, setEmail] = useState("");

  const handleForgotPassword = (e) => {
    e.preventDefault();
    onForgotPassword(email);
  };
  return (
    <div className="auth-data">
      <Form onSubmit={handleForgotPassword}>
        <div className="section-title">
          <div className="heading">
            <h2>Forgot Password</h2>
            <p>
              Don’t have an account?{" "}
              <a href="#" onClick={toggleForgotPassword}>
                Create now
              </a>
            </p>
          </div>
        </div>

        <div className="form-group">
          <label>E-mail</label>
          <input
            type="email"
            className="form-control"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="button-holder">
          <button type="submit" className="btn btn-primary">
            Reset Password
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPassword;
