// Loader.js
import React from "react";
import { InfinitySpin } from "react-loader-spinner";
const Loader = ({ isLoading }) => {
  console.log('isLoading', isLoading)
  if (!isLoading) return null;

  return (
    <div className="loader-overlay">
      <InfinitySpin  width="200" color="#FD9E03" />
    </div>
  );
};

export default Loader;
