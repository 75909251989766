import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Container,Form, Modal, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate, useSearchParams   } from "react-router-dom"; // Import NavLink from React Router
import {jwtDecode} from "jwt-decode";
import userApi from "../clientAPIInstance/userApi.js";
import InputGroup from 'react-bootstrap/InputGroup';
import logo from "../assets/images/logo.png"; // Import the logo image
import AskAI from "../assets/images/ask-ai.png"; // Import the logo image
import SignIn from "../components/Auth/SignIn";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Register from "../components/Auth/Register";
import { useToast } from '../customProviders/ToastProvider.js';
import { toggleLogin, tiggerSignupPopup, userLogin, userLogout } from '../store/slices/authSlice.js';
const HeaderComponent = () => {
  const timeoutRef = useRef(null); // To store the timeout ID
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast(); 
  
  const { tiggerLogin, userId, isAuthenticated, user } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const [isNavCollapsed, setIsNavCollapsed] = useState(null);
  const [showAIModal, setshowAIModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const navItems = [
    { name: "Home", path: "/" },
    { name: "Favorites", path: "/favorite" },
    { name: "Recently Viewed", path: "/recently-viewed" },
    { name: "Recently Searched", path: "/recently-searched" },
    { name: "File Upload", path: "/file-upload" },
    { name: "Contact Us", path: "/contact-us" },
  ];
  

  const handleOpen = () => setShowModal(true);

  const handleRegisterOpen = () => {
    setShowModal(true);
    setShowRegister(true); // Reset register state
  };

  const handleAiModal = () =>{
    setshowAIModal(true)
  }

  const handleClose = () => {
    setShowModal(false);
    setshowAIModal(false)
    setShowForgotPassword(false); // Reset forgot password state
    setShowRegister(false); // Reset register state
    dispatch(toggleLogin({open:false}));
    dispatch(tiggerSignupPopup({open:false}));
  };

  const handleSignIn = async(credentials) => {
    if(credentials.email && credentials.password){
      try{
        let data = await userApi.post('login', credentials)
        if(data && data.data){
        
          showToast('Logged in Successfully', 'success');
          const decoded = jwtDecode(data.data.access_token);
          localStorage.setItem("userID",btoa(decoded.sub))
          localStorage.setItem("userData",btoa(JSON.stringify(data.data)))
          localStorage.setItem("accessToken",data.data.access_token)
          dispatch(userLogin({userID:decoded.sub, user:data.data}));
          handleClose();
          window.location.href="/"
        }
      }
      catch(e){
        showToast(e.response?.data?.detail || e.message, 'error');
      }
    }
  };

  const handleForgotPassword = (email) => {
    console.log("Forgot password for:", email);
    handleClose(); // Close modal after submitting
  };

  const handleRegister = async(credentials) => {
    if(credentials.first_name && credentials.last_name && credentials.email && credentials.mobile_number && credentials.password){
      try{
        let data = await userApi.post('registeruser', credentials)
        if(data && data.data){
          showToast('Registered Successfully', 'success');
          const decoded = jwtDecode(data.data.access_token);
          localStorage.setItem("userID",btoa(decoded.sub))
          localStorage.setItem("accessToken",data.data.access_token)
          localStorage.setItem("userData",btoa(JSON.stringify(data.data)))
          dispatch(userLogin({userID:decoded.sub, user:data.data}));
          handleClose();
          window.location.href="/"
        }
      }
      catch(e){
        showToast(e.response?.data?.detail || e.message, 'error');
      }
    }
  };

  const toggleForgotPassword = () => {
    setShowForgotPassword((prev) => !prev);
    setShowRegister(false); // Reset register state when toggling
  };

  const toggleRegister = () => {
    setShowRegister((prev) => !prev);
    setShowForgotPassword(false); // Reset forgot password state when toggling
  };
  const toggleNav = () => setIsNavCollapsed(!isNavCollapsed);

  const handleNavigation = (searchURL) => {
    navigate(searchURL);  // Navigate to the About page
  };

  const userHeaderLogout = () =>{
    localStorage.removeItem("userID")
    localStorage.removeItem("accessToken")
    navigate('/');
    dispatch(userLogout([]));
  }

  const slidePlaceholder = (placeholders, interval) => {
    const inputEl = document.getElementById("search-input-placeholder");
    let currentIndex = 0;
    if(inputEl){
      timeoutRef.current =  setInterval(() => {
        inputEl.classList.add("animate-placeholder");
        setTimeout(() => {
          currentIndex = (currentIndex + 1) % placeholders.length;
          inputEl.placeholder = placeholders[currentIndex];
        }, 250); // Half of the animation duration
        setTimeout(() => {
          inputEl.classList.remove("animate-placeholder");
        }, 500);
      }, interval);
    }
  }

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    if(searchText){
      navigate('/location?query='+searchText)
    }
  };

  
  useEffect(()=>{
    let getSearchParams = searchParams.get('query');
    if(getSearchParams){
      setSearchText(getSearchParams)
    } 
  },[searchParams])


  useEffect(()=>{
    if(location.pathname!=='' && location.pathname!=='/' && searchText===''){
      slidePlaceholder(["Properties in Dallas", "Properties in Pennsylvania", "Properties in Atlanta", "Properties in Georgia"],2000);
    }
    else{
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  },[location, searchText])

  useEffect(() => {
    if (tiggerLogin) {
        handleOpen()
    }
  }, [tiggerLogin]); 


  return (
    <>
    <header>
      <Navbar expand="" className="navbar">
        <Container fluid>
          <Navbar.Brand>
            <div className="logo">
              <Link to="/">
                  <img src={logo} className="img-fluid" alt="Brand Logo" />
              </Link>
            </div>
          </Navbar.Brand>
          {(location.pathname!=='' && location.pathname!=='/') ? <div className="col-sm-6">
            <Form inline onSubmit={handleSubmit}>
              <InputGroup>
                <div className="search-holder-main">
                  <div className="search-holder">
                    <Form.Control
                      id="search-input-placeholder"
                      placeholder="Properties in Dallas"
                      aria-label="Properties in Dallas"
                      aria-describedby="basic-addon1"
                      value={searchText}
                      onChange={handleChange}
                    />
                    <InputGroup.Text id="basic-addon1" className="cursor-pointer" onClick={() => document.getElementById("submitButtonHeader").click()}>
                      <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3983 9.6229L1.71374 0.486093C0.862688 0.0200421 -0.0866681 0.917268 0.330625 1.79326L3.45691 8.35598C3.5893 8.63388 3.84229 8.83532 4.14278 8.9021L6.94049 9.52381C7.98458 9.75583 7.98458 11.2442 6.94049 11.4762L4.14278 12.0979C3.84229 12.1647 3.5893 12.3661 3.45691 12.644L0.330625 19.2067C-0.0866689 20.0827 0.862689 20.98 1.71374 20.5139L18.3983 11.3771C19.0913 10.9976 19.0913 10.0024 18.3983 9.6229Z" fill="url(#paint0_linear_663_2532)"/>
                        <defs>
                          <linearGradient id="paint0_linear_663_2532" x1="1.33333" y1="1.55555" x2="18.9767" y2="9.32247" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FD9E03"/>
                            <stop offset="1" stopColor="#EA3201"/>
                          </linearGradient>
                        </defs>
                      </svg>
                    </InputGroup.Text>
                  </div>
                  <button type="submit" id="submitButtonHeader" className="d-none"></button>
                </div>
                <button className="btn ask-button" type="button" onClick={()=>handleAiModal()}><img src={AskAI} alt="Ask AI"/></button>
                <Modal show={showAIModal} onHide={handleClose} centered>
                    <Modal.Header>
                      <h4>Chatbot Under Development !</h4>
                    </Modal.Header>
                    <Modal.Body>
                      The Propaity.ai chatbot is currently under development. Stay tuned for updates as we enhance your experience!
                    </Modal.Body>
                </Modal>
              </InputGroup>
            </Form>
          </div> : <></>}
          {!isAuthenticated ? <div className="login-signup-btn">
            <ul className="d-flex list-unstyled">
              <li className="me-3">
                <a href="#" className="login-btn" onClick={handleOpen}>
                  Login
                </a>
              </li>
              <li>
                <a href="#" className="signup-btn" onClick={handleRegisterOpen}>
                  Sign Up
                </a>
              </li>
            </ul>
          </div> : <div className="login-signup-btn">
          <NavDropdown
            title={(user) ? user.first_name+' '+user.last_name : ''}
            id="user-profile-dropdown"
            align="end"
           
          >
            {navItems.map((item, index) => (
                <NavDropdown.Item eventKey={item.path}  onClick={()=>handleNavigation(item.path)} key={index}>{item.name}</NavDropdown.Item>
            ))}
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="logout" onClick={()=>userHeaderLogout()}>Logout</NavDropdown.Item>
          </NavDropdown>
          </div>}
        </Container>
      </Navbar>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          {showForgotPassword ? (
            <ForgotPassword
              onForgotPassword={handleForgotPassword}
              toggleForgotPassword={toggleRegister}
            />
          ) : showRegister ? (
            <Register
              onRegister={handleRegister}
              toggleSignIn={toggleRegister}
            />
          ) : (
            <SignIn
              onSignIn={handleSignIn}
              toggleForgotPassword={toggleForgotPassword}
              toggleRegister={toggleRegister}
            />
          )}
        </Modal.Body>
      </Modal>
    </header>
    <div className={`header-menu-list ${isNavCollapsed ? 'show' : 'hide'}`}>
        <div className="close-button-holder">
          <div className="close-button" onClick={toggleNav} >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
